"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PepPipVerification = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var update_pep_pip_status_1 = require("../../api/calls/user/update_pep_pip_status");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../reducers/slices/main");
var PepPipVerification = function (props) {
    var _a = props.onProceed, onProceed = _a === void 0 ? function () { } : _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)(false), isSaving = _b[0], setIsSaving = _b[1];
    var _c = (0, react_1.useState)({
        pep_pip: false,
        foreign_pep: false,
        domestic_pep: false,
        clause_pep: false,
        is_pep: false,
        explanation: "",
        explanationError: "",
    }), fields = _c[0], setFields = _c[1];
    var fieldChanged = function (name, value) {
        setFields(function (prevFields) {
            var _a;
            return (__assign(__assign({}, prevFields), (_a = {}, _a[name] = value, _a)));
        });
    };
    var validateExplanation = function () {
        if (fields.is_pep && !fields.explanation) {
            fieldChanged("explanationError", "Explanation is required.");
            return false;
        }
        fieldChanged("explanationError", "");
        return true;
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateExplanation()) {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Explanation Required",
                            messages: ["Please provide an explanation if you have selected 'Yes' for PEP/PIP status."],
                        }));
                        return [2 /*return*/];
                    }
                    if (!fields.pep_pip || !fields.foreign_pep || !fields.domestic_pep || !fields.clause_pep) {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Terms Agreement Required",
                            messages: ["Please agree to all the terms and conditions."],
                        }));
                        return [2 /*return*/];
                    }
                    if (!user.uid) {
                        fieldChanged("explanationError", "User ID is missing.");
                        return [2 /*return*/];
                    }
                    setIsSaving(true);
                    request = {
                        user_id: user.uid,
                        pep_confimation: {
                            pep_pip: fields.pep_pip,
                            foreign_pep: fields.foreign_pep,
                            domestic_pep: fields.domestic_pep,
                            clause_pep: fields.clause_pep,
                        },
                        pep_reason: fields.explanation,
                        is_pep: fields.is_pep ? "Yes" : "No",
                    };
                    return [4 /*yield*/, (0, update_pep_pip_status_1.update_pep_pip_status)(request)];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, main_1.setContentModal)(null));
                        onProceed();
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Error",
                            messages: [response.message],
                        }));
                    }
                    setIsSaving(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 w-[700px] h-[500px] overflow-y-auto" },
        react_1.default.createElement("p", { className: "text-sm" }, "Below are a set of definitions and explanations of the term \"PEP/PIP\"."),
        react_1.default.createElement("p", { className: "text-sm" }, "Please read through each definition and tick the corresponding box of that definition, to verify that you have read and understood the definition of a PEP/PIP."),
        react_1.default.createElement("div", { className: "border-b border-gray-300" }),
        react_1.default.createElement("div", { className: "flex flex-row items-center justify-between gap-6 p-4 border border-gray-300 rounded-md shadow" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", textColor: "text-black", label: "Politically Exposed Persons" }),
                react_1.default.createElement("p", { className: "text-sm" }, "Politically Exposed Persons\u00A0also known as\u00A0Prominent Influential Persons (PIP)\u00A0are individuals who hold or have held prominent public positions or have close associations with such individuals.")),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: fields.pep_pip, onChange: function (v) {
                    fieldChanged("pep_pip", v);
                } })),
        react_1.default.createElement("div", { className: "flex flex-row items-center justify-between gap-6 p-4 border border-gray-300 rounded-md shadow" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", textColor: "text-black", label: "Foreign PEPs" }),
                react_1.default.createElement("p", { className: "text-sm" }, "Foreign PEPs\u00A0are individuals who are or have been entrusted with prominent public functions by a foreign country, for example Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials.")),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: fields.foreign_pep, onChange: function (v) {
                    fieldChanged("foreign_pep", v);
                } })),
        react_1.default.createElement("div", { className: "flex flex-row items-center justify-between gap-6 p-4 border border-gray-300 rounded-md shadow" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", textColor: "text-black", label: "Domestic PEPs" }),
                react_1.default.createElement("p", { className: "text-sm" }, "Domestic PEPs\u00A0are individuals who are or have been entrusted domestically with prominent public functions, for example Heads of State or of government, senior politicians, senior government, judicial or military officials, senior executives of state owned corporations, important political party officials.")),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: fields.domestic_pep, onChange: function (v) {
                    fieldChanged("domestic_pep", v);
                } })),
        react_1.default.createElement("div", { className: "flex flex-row items-center justify-between gap-6 p-4 border border-gray-300 rounded-md shadow" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "medium", textColor: "text-black", label: "" }),
                react_1.default.createElement("p", { className: "text-sm" }, "Persons who are or have been entrusted with a prominent function by an international organisation\u00A0refers to members of senior management, i.e. directors, deputy directors and members of the board or equivalent functions."),
                react_1.default.createElement("p", { className: "text-sm" }, "The definition of PEPs is not intended to cover middle ranking or more junior individuals in the foregoing categories.")),
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: fields.clause_pep, onChange: function (v) {
                    fieldChanged("clause_pep", v);
                } })),
        react_1.default.createElement("div", { className: "border-b border-gray-300" }),
        react_1.default.createElement("div", { className: "flex flex-col items-center gap-2" },
            react_1.default.createElement("p", null, "Making use of the definitions above, are you a PEP/PIP?"),
            react_1.default.createElement("div", { className: "flex w-full justify-evenly" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "Yes", value: fields.is_pep, onChange: function () {
                        fieldChanged("is_pep", true);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "No", value: !fields.is_pep, onChange: function () {
                        fieldChanged("is_pep", false);
                    } })),
            fields.is_pep && (react_1.default.createElement("div", { className: "flex flex-col items-center justify-center w-full" },
                react_1.default.createElement("input", { type: "text", className: "w-2/3 p-2 border border-gray-300 rounded-md", placeholder: "Please provide more details", value: fields.explanation, onChange: function (e) {
                        fieldChanged("explanation", e.target.value);
                    } }),
                fields.explanationError && react_1.default.createElement("p", { className: "text-sm text-red-500" }, fields.explanationError))),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-secondary", size: "small", loading: isSaving, onClick: handleSubmit }))));
};
exports.PepPipVerification = PepPipVerification;
